'use client'

import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
  Control,
  RegisterOptions,
  useController,
} from 'react-hook-form'

import { cn } from '@/lib/utils'
import { Label } from '@/components/ui/Label'
import { Input } from './Input'
import { Select } from './Select'
import { Option } from './utils'
import { Combobox } from './Combobox'
import { useDebouncedCallback } from 'use-debounce'
import { Textarea } from './Textarea'
import { Checkbox } from './Checkbox'
import { DatePicker } from './DatePicker'
import { DateRangePicker } from './DateRangePicker'
import { InputTags } from './InputTags'
import FileInput from './FileInput'
import { MoneyRange } from './MoneyRange'
import { StringRange } from '@core/util/types'

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

interface FormFieldProps<Form extends FieldValues> {
  control: Control<Form>
  name: FieldPath<Form>
  label?: string
  description?: string
  rules?: RegisterOptions<Form>
}

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue)

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const itemContext = React.useContext(FormItemContext)
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue)

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId()

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn('relative flex flex-col gap-2', className)} {...props} />
      </FormItemContext.Provider>
    )
  },
)
FormItem.displayName = 'FormItem'

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField()

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-red-500 dark:text-red-900', className)}
      htmlFor={formItemId}
      {...props}
    />
  )
})
FormLabel.displayName = 'FormLabel'

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
  ({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

    return (
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
        aria-invalid={!!error}
        {...props}
      />
    )
  },
)
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField()

    return (
      <p
        ref={ref}
        id={formDescriptionId}
        className={cn('text-sm text-slate-500 dark:text-slate-400', className)}
        {...props}
      />
    )
  },
)
FormDescription.displayName = 'FormDescription'

const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField()
    const body = error ? String(error?.message) : children

    if (!body) {
      return null
    }

    return (
      <p
        ref={ref}
        id={formMessageId}
        className={cn('text-sm font-medium text-red-500 dark:text-red-900 whitespace-pre-wrap', className)}
        {...props}
      >
        {body}
      </p>
    )
  },
)
FormMessage.displayName = 'FormMessage'

export const FormInput = <Form extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  type,
  description,
  rules,
  format,
  autoFocus,
  disabled,
  classes,
}: FormFieldProps<Form> & {
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  format?: 'money'
  classes?: { root?: string }
  disabled?: boolean
  autoFocus?: boolean
}) => {
  return (
    <FormField
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <FormItem className={cn(classes?.root)}>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Input
                placeholder={placeholder}
                type={type}
                autoFocus={autoFocus}
                value={value ?? ''}
                format={format}
                disabled={disabled}
                onChange={onChange}
                {...field}
              />
            </FormControl>

            {description && <FormDescription>{description}</FormDescription>}

            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

export const FormTextarea = <Form extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  description,
  rules,
  disabled,
  className,
  classes,
  onChange: onChange_,
}: FormFieldProps<Form> & {
  placeholder?: string
  className?: string
  classes?: { textArea?: string }
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}) => {
  return (
    <FormField
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, ...field } }) => {
        const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange?.(e)
          onChange_?.(e)
        }

        return (
          <FormItem className={cn('relative flex flex-col gap-1', className)}>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Textarea
                disabled={disabled}
                placeholder={placeholder}
                onChange={_onChange}
                className={classes?.textArea}
                {...field}
              />
            </FormControl>

            {description && <FormDescription>{description}</FormDescription>}

            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

export const FormSelect = <Form extends FieldValues>({
  control,
  name,
  description,
  placeholder = 'Select...',
  options,
  label,
  rules,
  clearable,
  classes,
}: FormFieldProps<Form> & {
  options: Option[]
  placeholder?: string
  clearable?: boolean
  classes?: { root?: string }
}) => {
  return (
    <FormField
      rules={rules}
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className={cn('relative flex flex-col gap-1', classes?.root)}>
            {label && <FormLabel>{label}</FormLabel>}

            <Select
              value={field.value}
              options={options}
              onSelect={(option) => field.onChange(option)}
              clearable={clearable}
              placeholder={placeholder}
              key={field.value?.value}
            />

            {description && <FormDescription>{description}</FormDescription>}

            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

interface BaseComboboxProps<Value extends string, Data = any> {
  placeholder?: string
  isLoading?: boolean
  multiselect?: boolean
  clearable?: boolean
  classes?: { root?: string; trigger?: string; content?: string }
  hideFilter?: boolean
  disabled?: boolean
}

interface FormAsyncComboboxProps<Form extends FieldValues, Value extends string = string, Data = any>
  extends FormFieldProps<Form>,
    BaseComboboxProps<Value, Data> {
  loadOptions: (query?: string) => Promise<Option<Value, Data>[]>
  onSelect?: (option: Option<Value, Data> | Option<Value, Data>[] | null) => void
  modal?: boolean
  maxBadges?: number
}

export const FormAsyncCombobox = <Form extends FieldValues, Value extends string = string, Data = any>({
  rules,
  control,
  name,
  label,
  description,
  placeholder,
  multiselect,
  isLoading,
  loadOptions,
  onSelect,
  hideFilter,
  clearable,
  classes,
  modal,
  disabled,
  maxBadges,
}: FormAsyncComboboxProps<Form, Value, Data>) => {
  const { field } = useController<Form>({ name, control })
  const [options, setOptions] = React.useState<Option<Value, Data>[]>([])
  const [isLoadingOptions, setIsLoadingOptions] = React.useState(false)

  const _loadOptions = useDebouncedCallback(async (query?: string) => {
    setIsLoadingOptions(true)
    setOptions(await loadOptions(query))
    setIsLoadingOptions(false)
  }, 200)

  const handleSelect = (option: Option<Value, Data> | Option<Value, Data>[] | null) => {
    field.onChange(option)
    onSelect?.(option)
  }

  React.useEffect(() => {
    _loadOptions()
  }, [_loadOptions, loadOptions])

  const currentOption = field.value as Option<Value, Data> | Option<Value, Data>[] | null

  return (
    <FormField
      rules={rules}
      control={control}
      name={name}
      render={() => {
        return (
          <FormItem className={cn(classes?.root)}>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Combobox
                defaultValue={currentOption}
                options={options}
                shouldFilter={false}
                isLoading={isLoading}
                isLoadingOptions={isLoadingOptions}
                optionPlaceholder={placeholder}
                onInputChange={_loadOptions}
                onSelect={handleSelect}
                classes={{ trigger: cn('w-full', classes?.trigger), content: classes?.content }}
                multiselect={multiselect}
                value={currentOption}
                hideFilter={hideFilter}
                clearable={clearable}
                onOpenChange={(open) => !open && _loadOptions()}
                modal={modal}
                disabled={disabled}
                maxBadges={maxBadges}
              />
            </FormControl>

            {description && <FormDescription>{description}</FormDescription>}

            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

export interface FormComboboxProps<Form extends FieldValues, Value extends string = string, Data = any>
  extends FormFieldProps<Form>,
    BaseComboboxProps<Value, Data> {
  options: Option<Value, Data>[]
  modal?: boolean
  shouldFilter?: boolean
  onSelect?: (option: Option<Value, Data> | Option<Value, Data>[] | null) => void
}

export const FormCombobox = <Form extends FieldValues, Value extends string = string, Data = any>({
  rules,
  control,
  name,
  label,
  description,
  placeholder,
  multiselect,
  isLoading,
  options,
  clearable,
  hideFilter,
  disabled,
  shouldFilter,
  onSelect,
  classes,
  modal,
}: FormComboboxProps<Form, Value, Data>) => {
  const { field } = useController<Form>({ name, control })

  const handleSelect = (option: Option<Value, Data> | Option<Value, Data>[] | null) => {
    field.onChange(option)
    onSelect?.(option)
  }

  const currentOption = field.value

  return (
    <FormField
      rules={rules}
      control={control}
      name={name}
      render={() => {
        return (
          <FormItem className={cn(classes?.root)}>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Combobox
                defaultValue={currentOption}
                options={options}
                isLoading={isLoading}
                optionPlaceholder={placeholder}
                onSelect={handleSelect}
                classes={{ trigger: cn('w-full', classes?.trigger), content: classes?.content }}
                multiselect={multiselect}
                value={currentOption}
                hideFilter={hideFilter}
                clearable={clearable}
                disabled={disabled}
                modal={modal}
                shouldFilter={shouldFilter}
              />
            </FormControl>

            {description && <FormDescription>{description}</FormDescription>}

            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

const FormCheckbox = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
}: FormFieldProps<Form> & {
  classes?: { root?: string; label?: string }
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormItem className={cn('flex flex-row items-center space-y-0 rounded-md h-9 px-4 border', classes?.root)}>
          <FormControl>
            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
          </FormControl>
          <div className="space-y-1 leading-none font-normal ml-1">
            <FormLabel className={cn('cursor-pointer', classes?.label)}>{label}</FormLabel>
          </div>
          <FormDescription>{description}</FormDescription>
        </FormItem>
      )}
    />
  )
}

const FormDatePicker = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
  disabed,
  placeholder,
}: FormFieldProps<Form> & {
  classes?: {
    root?: string
    datePicker?: {
      trigger?: string
      content?: string
    }
  }
  disabed?: boolean
  placeholder?: string
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormItem className={classes?.root}>
          <FormLabel>{label}</FormLabel>
          <DatePicker
            value={field.value}
            onChange={field.onChange}
            disabled={disabed}
            placeholder={placeholder}
            classes={classes?.datePicker}
          />
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const FormDateRangePicker = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
  disabled,
  placeholder,
  defaultEndDateToTodayIfEmpty,
}: FormFieldProps<Form> & {
  classes?: { trigger?: string; content?: string }
  disabled?: boolean
  placeholder?: string
  defaultEndDateToTodayIfEmpty?: boolean
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <DateRangePicker
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
            placeholder={placeholder}
            classes={classes}
            defaultEndDateToTodayIfEmpty={defaultEndDateToTodayIfEmpty}
          />
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const _InputTags = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
  placeholder,
  maxBadges,
}: FormFieldProps<Form> & {
  classes?: { root?: string; trigger?: string; content?: string }
  placeholder?: string
  maxBadges?: number
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <FormItem className={classes?.root}>
            {label && <FormLabel>{label}</FormLabel>}
            <InputTags
              value={field.value}
              onChange={(v) => field.onChange(v)}
              placeholder={placeholder}
              maxBadges={maxBadges}
            />
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

const _FileInput = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
  multiple,
  disabled,
  accept,
}: FormFieldProps<Form> & {
  disabled?: boolean
  classes?: { root?: string }
  multiple: boolean
  accept?: string
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      disabled={disabled}
      render={({ field }) => {
        return (
          <FormItem className={classes?.root}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
              <FileInput
                multiple={multiple}
                disabled={disabled}
                accept={accept}
                onChange={(value: File | File[] | null) => field.onChange(value)}
              />
            </FormControl>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}

const _MoneyRange = <Form extends FieldValues>({
  name,
  control,
  label,
  description,
  rules,
  classes,
  disabled,
  placeholder,
  onChange,
}: FormFieldProps<Form> & {
  classes?: { root?: string; trigger?: string; content?: string }
  disabled?: boolean
  placeholder?: string
  onChange?: (value: StringRange) => void
}) => {
  return (
    <FormField
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <FormItem className={classes?.root}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
              <MoneyRange
                value={field.value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(value) => {
                  field.onChange(value)
                  onChange?.(value)
                }}
                classes={{
                  trigger: classes?.trigger,
                  content: classes?.content,
                }}
              />
            </FormControl>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormInput as Input,
  FormTextarea as Textarea,
  FormSelect as Select,
  FormAsyncCombobox as AsyncCombobox,
  FormCombobox as Combobox,
  FormCheckbox as Checkbox,
  FormDatePicker as DatePicker,
  FormDateRangePicker as DateRangePicker,
  _InputTags as InputTags,
  _FileInput as FileInput,
  _MoneyRange as MoneyRange,
}
