'use client'

import * as React from 'react'
import { Calendar as CalendarIcon, XIcon } from 'lucide-react'
import { Button } from '@/components/ui/Button'
import { Calendar } from '@/components/ui/Calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/Popover'
import { cn } from '@/lib/utils'
import { dateToYMD } from '@core/util/date'
import { DateInput } from './DateInput'

interface Props {
  value?: Date
  disabled?: boolean
  placeholder?: string
  classes?: {
    trigger?: string
    content?: string
  }

  onChange?: (date?: Date) => void
}

export function DatePicker({ placeholder, disabled, classes, value, onChange }: Props) {
  const [displayMonth, setDisplayMonth] = React.useState(value)

  const handleClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    onChange?.(undefined)
  }

  return (
    <Popover onOpenChange={() => setDisplayMonth(value)}>
      <PopoverTrigger asChild>
        <div className="relative overflow-hidden">
          {value && (
            <Button
              variant="ghost"
              className="p-0 opacity-50 h-fit hover:opacity-100 absolute right-1 top-3"
              onClick={handleClearClick}
              type="button"
            >
              <XIcon size={14} />
            </Button>
          )}
          <Button
            variant={'outline'}
            className={cn(
              'w-[280px] justify-start text-left font-normal',
              !value && 'text-muted-foreground',
              classes?.trigger,
            )}
            type="button"
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            <span className="overflow-hidden text-ellipsis">
              {value ? dateToYMD(value) : <span>{placeholder || 'Pick a date'}</span>}
            </span>
          </Button>
        </div>
      </PopoverTrigger>

      <PopoverContent className={cn('w-auto p-2', classes?.content)}>
        <div className="relative border border-slate-200 pr-1 pl-9 rounded-md h-9 flex items-center flex-1">
          <DateInput
            value={value}
            onChange={(date) => {
              setDisplayMonth(date)
              onChange?.(date)
            }}
          />
          <CalendarIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
        </div>

        <Calendar
          mode="single"
          selected={value}
          onSelect={onChange}
          disabled={disabled}
          defaultMonth={value}
          onMonthChange={(month) => setDisplayMonth(month)}
          month={displayMonth}
        />
      </PopoverContent>
    </Popover>
  )
}
