'use client'

import { Label } from '@/components/ui/Label'
import { Input } from '@/components/ui/Input'
import { useMemo } from 'react'
import { v4 } from 'uuid'

export type FileInputProps = {
  label?: string
  disabled?: boolean
  accept?: string
} & (
  | {
      multiple: true
      onChange: (files: File[] | null) => void
    }
  | {
      multiple?: false
      onChange: (file: File | null) => void
    }
)

export default function FileInput({ label, disabled, multiple, onChange, accept }: FileInputProps) {
  const id = useMemo(() => v4(), [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (!files) {
      onChange(null)
      return
    }

    if (multiple) {
      onChange(Array.from(files))
    } else {
      onChange(files[0])
    }
  }

  return (
    <div className="w-full grid gap-2">
      {label && <Label htmlFor={id}>{label}</Label>}

      <Input id={id} type="file" multiple={multiple} onChange={handleChange} disabled={disabled} accept={accept} />
    </div>
  )
}
